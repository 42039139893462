/* eslint-disable @typescript-eslint/no-explicit-any */
// 3rd
import { Flex, useToken } from '@chakra-ui/react';
import {
  ResponsiveContainer as ReChartsResponsiveContainer,
  BarChart as ReChartsBarChart,
  Bar as ReChartsBar,
  Legend as ReChartsLegend,
} from 'recharts';
import type { Payload } from 'recharts/types/component/DefaultLegendContent';

// App - Other
import { CircleIcon } from '@/components/atoms/icon';
import { Text } from '@/components/atoms/typography';

type BarProps = {
  dataKey: string;
  color: string;
  name: string;
};

type BarsProps = {
  data: any[];
  bars: BarProps[];
};

export const Bars = ({ data, bars }: BarsProps) => {
  const [barLegendTextColor] = useToken('colors', ['text.invert-primary']);

  return (
    <ReChartsResponsiveContainer width="100%" height="100%">
      <ReChartsBarChart data={data} barGap={6}>
        {bars.map((bar) => (
          <ReChartsBar
            key={bar.dataKey}
            dataKey={bar.dataKey}
            fill={bar.color}
            radius={[4, 4, 0, 0]}
            name={bar.name}
            minPointSize={25}
            barSize={35}
            label={{
              fill: barLegendTextColor,
              fontSize: 18,
              fontWeight: 600,
              position: 'insideBottom',
              formatter: (value: number) => {
                if (value > 999) return `${value / 1000}K`;
                else if (value > 999999) return `${value / 1000000}M`;
                else return value;
              },
            }}
          />
        ))}

        <ReChartsLegend
          layout="vertical"
          verticalAlign="bottom"
          align="left"
          content={(props) => <RenderLegend payload={props.payload || []} />}
          wrapperStyle={{
            width: '50%',
            paddingInlineStart: '5%',
            paddingInlineEnd: '4%',
            paddingBottom: '2%',
          }}
        />
      </ReChartsBarChart>
    </ReChartsResponsiveContainer>
  );
};

type RenderLegendProps = {
  payload: Payload[];
};

const RenderLegend = ({ payload }: RenderLegendProps) => {
  const legendText = (value: string) => {
    if (value && value.length) {
      return value[0].toUpperCase() + value.substring(1);
    }

    return value;
  };

  return (
    <Flex direction="column">
      {payload.map((entry, index) => (
        <Flex key={`item-${entry.value}-${index}`} alignItems="center" gap="xs">
          <CircleIcon
            w="6px"
            h="6px"
            aria-label="Legend Circle Icon"
            zIndex={1}
            color={entry.color}
          />

          <Text variant="small">{legendText(entry.value)}</Text>
        </Flex>
      ))}
    </Flex>
  );
};
